import { useState, useEffect } from "react";
import { useContentfulBetSlip } from "../../services/useContentfulBetSlip";
import {
  getContentfulImage,
  getKeyValue,
  getLocale,
  handleMarkdownLink,
  getCountryCode,
  showSectionIfText,
  fetchFixtueDetails,
} from "../../utils/common.utils";
import {
  betSlipActions,
  betStatusConstants,
  countryCodes,
} from "../../constants/betslip.constants";
import { getSnapshot } from "mobx-state-tree";
import MarkdownContent from "../MarkdownContent/MarkdownContent";
import styles from "./styles/BetSlip.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { DaznBetLogo, LinkIcon, Spin } from "./reusable/svgUtils";
import { messageToNative } from "../../services/nativeApp.service";
import localisePrice from "../../utils/formatCurrency";
import { Question } from "./Question";
import { ErrorComponent } from "./ErrorComponent";
import { betCcbEvents, betErrorTypes } from "../../constants/betslip.constants";
import { getPotentialBetAmount, ssoFunction } from "../../utils/betslip.utils";
import { nativeUiConfig } from "../../utils/native.config";
import PlacedBets from "./PlacedBets";
import { Player } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import BettingNotStarted from "./BettingNotStarted";
import Header from "./Header";
import { FixtureDetailsScreen, FreeBet } from "./utils";
import SlimBreathers from "./Breathers/SlimBreathers";

export const BetSlipForm = ({ betStore }) => {
  const {
    answers,
    calculateUserPotentialReturns,
    potentialReturnsResponse,
    getBetDetails,
    betDetailsRes,
    getUserBetDetails,
    userBetDetails,
    isApiError,
    setIsApiError,
    isButtonLoading,
    setIsButtonLoading,
    resetAnswers,
  } = betStore;

  const [isBetPlaced, setIsBetPlaced] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [isUserWonAtleastOne, setIsUserWonAtleastOne] = useState(false);
  const [animationData, setAnimationData] = useState(null);
  const [showFullDetails, setShowFullDetails] = useState(true);
  const [fixtureDetails, setFixtureDetails] = useState(null);
  const { contentfulData } = useContentfulBetSlip();
  const contentfulStrings = (key) => getKeyValue(contentfulData, key);
  const contentfulImages = (imageKey) =>
    getContentfulImage(contentfulData, imageKey);
  let { currencySymbol, nextAction, betDetails, currency, eventStatus } =
    betDetailsRes;
  eventStatus = eventStatus?.toLowerCase();
  const locale = getLocale();
  const countryCode = getCountryCode() ? getCountryCode() : "GB";
  const [isButtonActive, setIsButtonActive] = useState(false);
  const isEligibleToBet = nextAction !== betSlipActions.INELIGIBLE;
  const isEventLive =
    nextAction === betSlipActions.BET_CLOSED &&
    eventStatus === betStatusConstants.LIVE;
  const isEventCompleted =
    nextAction === betSlipActions.BET_CLOSED &&
    eventStatus === betStatusConstants.COMPLETED;
  const isPlaceBet = nextAction === betSlipActions.PLACE_BET;
  const { showNavBar, screenPercentage, dismissible, isFullScreen } =
    nativeUiConfig[countryCode] || nativeUiConfig.DEFAULT;

  const selectedAnswers = getSnapshot(answers);
  const isGermany = countryCodes.GERMANY === countryCode;

  window.daznNativeBridge.messageToWeb = async function (event, payload) {
    if (event === "betSlipReset") {
      setIsButtonLoading(false);
      if (payload?.isBetPlaced) {
        // Reset Answers only if the user has placed a bet
        resetAnswers();

        // showing history in expanded state , only if the user places a bet and he can able to see the latest one in top of the history
        setShowFullDetails(true);
      }

      await getBetDetails(payload);
      await getUserBetDetails();
    }
  };

  useEffect(() => {
    // web analytics

    const onPageLoadFunction = () =>
      messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
        analyticEvent: "onPageLoad",
      });

    setTimeout(onPageLoadFunction, 4000);

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams?.get("_nativeApp") === "android") {
      document.body.style.minHeight = "800px";
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getBetDetails();
    };
    fetchData();
    // polling getBetDetails
    const intervalId = setInterval(async () => {
      await getBetDetails();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (isGermany && betDetailsRes.daznFixtureId && !fixtureDetails) {
      (async () => {
        const fixtureDetails = await fetchFixtueDetails(
          betDetailsRes.daznFixtureId
        );
        setFixtureDetails(fixtureDetails);
      })();
    }
  }, [betDetailsRes]);

  useEffect(() => {
    const freeBetGifUrl = contentfulStrings("freeBetGifUrl");
    if (!freeBetGifUrl) return;

    axios
      .get(freeBetGifUrl)
      .then((response) => {
        setAnimationData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [contentfulStrings("freeBetGifUrl")]);

  useEffect(() => {
    (async () => {
      // for germany we wont be showing bet history
      if (isGermany || nextAction === betSlipActions.BETTING_NOT_STARTED)
        return;
      if (
        nextAction &&
        nextAction !== betSlipActions.INELIGIBLE &&
        nextAction !== betSlipActions.REGISTER
      ) {
        setIsHistoryLoading(true);
        await getUserBetDetails();
        setIsHistoryLoading(false);
      }
    })();
  }, [nextAction]);

  useEffect(() => {
    setIsBetPlaced(Boolean(userBetDetails?.betHistory?.length));
    userBetDetails?.betHistory?.forEach((betHistory) => {
      if (betHistory?.betStatus?.toLowerCase() === betStatusConstants.WON) {
        setIsUserWonAtleastOne(true);
      }
    });
  }, [userBetDetails]);

  // polling getUserBetDetails
  useEffect(() => {
    let intervalId;
    (async () => {
      if (isGermany) return;
      if (
        eventStatus === betStatusConstants.LIVE ||
        eventStatus === betStatusConstants.COMPLETED
      ) {
        await getUserBetDetails();
      }
      if (eventStatus === betStatusConstants.COMPLETED) {
        intervalId = setInterval(async () => {
          await getUserBetDetails();
        }, 300000);
      }
    })();
    return () => clearInterval(intervalId);
  }, [eventStatus]);

  useEffect(() => {
    setIsButtonActive(
      selectedAnswers.length === betDetails?.questions?.length &&
        potentialReturnsResponse
    );
  }, [selectedAnswers, potentialReturnsResponse]);

  useEffect(() => {
    const calculateReturns = async () => {
      if (selectedAnswers.length === betDetails?.questions?.length) {
        await calculateUserPotentialReturns({
          questions: getSnapshot(answers),
        });
      }
    };
    calculateReturns();
  }, [selectedAnswers]);

  if (!contentfulData || !betDetailsRes.status)
    return <DaznBetLogo locale={locale.toLowerCase()} />;

  const handleBetButton = async (type) => {
    // web analytics
    messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
      analyticEvent: type,
    });
    let redirectUrl;
    const { selectionId, eventId, marketId, price, betRedirectionUri } =
      potentialReturnsResponse;

    if (!isGermany) {
      setIsButtonLoading(true);

      const sportsBookKey = {
        selectionId,
        eventId,
        marketId,
        price,
      };

      let encodedJosn;

      if (window) {
        encodedJosn = window.btoa(JSON.stringify(sportsBookKey));
      }
      let newRedirectionUrl = new URL(betRedirectionUri);
      newRedirectionUrl.searchParams.append("sportsBookKey", encodedJosn);

      redirectUrl = newRedirectionUrl;
      if (type === betSlipActions.REGISTER || type === betSlipActions.LOGIN) {
        try {
          redirectUrl = await ssoFunction(newRedirectionUrl.href);
          setIsApiError(false);
        } catch (errorHref) {
          redirectUrl = errorHref;
          setIsApiError(true);
        }
      } else if (
        type === betSlipActions.PLACE_BET &&
        potentialReturnsResponse?.userDetails
      ) {
        newRedirectionUrl.searchParams.append(
          "userDetails",
          potentialReturnsResponse.userDetails
        );
        redirectUrl = newRedirectionUrl.href;
      }

      if (!redirectUrl) {
        redirectUrl = newRedirectionUrl.href;
      }
    } else {
      let newRedirectionUrl = new URL(betRedirectionUri);
      newRedirectionUrl.searchParams.append(
        "id",
        `${eventId}_${marketId}_${selectionId}`
      );
      redirectUrl = newRedirectionUrl.href;
    }

    messageToNative(
      type === betSlipActions.REGISTER
        ? betCcbEvents.REGISTER
        : betCcbEvents.LOGIN,
      {
        isExternal: contentfulStrings("isExternal") === "true" ? true : false,
        url: redirectUrl,
        showNavBar,
        screenPercentage: screenPercentage[type],
        dismissible,
        nextAction,
        isFullScreen,
      }
    );
  };

  const handleMoreBets = async () => {
    const morebetsUrl = contentfulStrings("moreBetsButtonLink");
    if (!morebetsUrl) return;
    // Analytics
    messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
      analyticEvent: "moreBets",
    });

    messageToNative(betCcbEvents.DAZN_BET, {
      isExternal: true,
      url: morebetsUrl,
    });
  };

  const handleLiveTracking = async () => {
    const liveTrackingUrl = contentfulStrings("liveTrackingLink");
    if (!liveTrackingUrl) return;
    // Analytics
    messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
      analyticEvent: "liveTracking",
    });
    messageToNative(betCcbEvents.DAZN_BET, {
      isExternal: true,
      url: liveTrackingUrl,
    });
  };

  const handleFreeBetTerms = async () => {
    messageToNative(betCcbEvents.DAZN_BET, {
      isExternal: true,
      url: contentfulStrings("freeBetTermsUrl"),
    });
  };

  const getButtonText = (type) => {
    if (type === betSlipActions.REGISTER) {
      return contentfulStrings("registerText");
    } else if (betSlipActions.LOGIN) {
      return contentfulStrings("signinText");
    } else if (betSlipActions.PLACE_BET) {
      if (isBetPlaced) {
        return contentfulStrings("placeAnotherbet");
      } else {
        return contentfulStrings("placeBetButtonText");
      }
    } else {
      return "place bet";
    }
  };

  const SwitchButton = ({ type }) => {
    switch (type) {
      case betSlipActions.REGISTER:
      case betSlipActions.LOGIN:
      case betSlipActions.PLACE_BET:
        return (
          <button
            className={classNames({
              [styles.activateBet]: true,
              [styles.isButtonActive]: isButtonActive,
              [styles.dynamicActivateBet]:
                isGermany &&
                showSectionIfText(contentfulStrings("eighteenPlus")),
            })}
            onClick={() => handleBetButton(type)}
            disabled={!isButtonActive || isButtonLoading}
          >
            {getButtonText()}
          </button>
        );
      case "spin":
        return (
          <button
            className={classNames({
              [styles.activateBet]: true,
              [styles.isButtonActive]: isButtonActive,
              [styles.dynamicActivateBet]:
                isGermany &&
                showSectionIfText(contentfulStrings("eighteenPlus")),
            })}
            disabled={!isButtonActive || isButtonLoading}
          >
            <Spin width={24} height={24} color="black" />
          </button>
        );
      default:
        return <div>{contentfulStrings("nonMatchedButtonText")}</div>;
    }
  };

  SwitchButton.propTypes = {
    type: PropTypes.string.isRequired,
  };

  const PotentialReturnsString = () => {
    const baseAmount = getPotentialBetAmount();
    const oddAmount = potentialReturnsResponse?.oddAmount
      ? Number(potentialReturnsResponse?.oddAmount) * baseAmount
      : 0;

    return (
      <>
        <MarkdownContent
          className={classNames(styles.returnsHint)}
          templateValues={{
            potentialReturns: localisePrice(
              Number(oddAmount),
              currency,
              locale
            ),
            baseAmount:
              locale === "en-GB"
                ? `${currencySymbol}${baseAmount}`
                : `${baseAmount}${currencySymbol}`,
          }}
          component="p"
        >
          {contentfulStrings("potentialReturnsString")}
        </MarkdownContent>
      </>
    );
  };

  return (
    <div className={classNames(styles.main)}>
      {contentfulData && betDetailsRes.status && (
        <div className={classNames(styles.main)}>
          {!isGermany && (
            <Header contentfulStrings={contentfulStrings} inside={false} />
          )}

          {nextAction === betSlipActions.REGISTER && !isGermany && (
            <FreeBet
              contentfulStrings={contentfulStrings}
              animationData={animationData}
            />
          )}

          {isHistoryLoading ? (
            <div className={styles.threeDotsDiv}>
              <div className={styles.dotsPulse}></div>
            </div>
          ) : (
            isBetPlaced && (
              <div className={classNames(styles.placedBetsBody)}>
                <PlacedBets
                  betStore={betStore}
                  contentfulStrings={contentfulStrings}
                  showFullDetails={showFullDetails}
                  setShowFullDetails={setShowFullDetails}
                />
              </div>
            )
          )}

          {isBetPlaced && !isEventLive && !isEventCompleted && (
            <div className={styles.anotherMatchTitleDiv}>
              <p className={styles.leftBorder}></p>
              <p className={styles.anotherMatchTitle}>
                {contentfulStrings("buildAnotherMatch")}
              </p>
              <p className={styles.rightBorder}></p>
            </div>
          )}

          <div
            className={classNames(styles.body, {
              [styles.dynamicStylesBody]: isGermany,
            })}
          >
            {isEligibleToBet &&
            nextAction !== betSlipActions.BET_CLOSED &&
            nextAction !== betSlipActions.BETTING_NOT_STARTED &&
            !isApiError ? (
              <div className={classNames(styles.nonErrorDiv)}>
                <section className={classNames(styles.bodyContent)}>
                  {isGermany && (
                    <>
                      <Header
                        contentfulStrings={contentfulStrings}
                        inside={true}
                      />
                      <FixtureDetailsScreen fixtureDetails={fixtureDetails} />
                      {nextAction === betSlipActions.REGISTER && isGermany && (
                        <FreeBet
                          contentfulStrings={contentfulStrings}
                          animationData={animationData}
                        />
                      )}
                    </>
                  )}

                  <MarkdownContent
                    className={classNames(styles.questionsTitle, {
                      [styles.dynamicQuestionsTitle]: isGermany,
                    })}
                    templateValues={{
                      noOfQuestions: betDetails?.questions?.length,
                    }}
                  >
                    {contentfulStrings("questionsTitle")}
                  </MarkdownContent>

                  {betDetails?.questions?.map((data) => (
                    <Question key={data.id} data={data} betStore={betStore} />
                  ))}
                  {isButtonActive && potentialReturnsResponse?.oddAmount && (
                    <PotentialReturnsString />
                  )}
                  <SwitchButton
                    type={
                      isButtonLoading
                        ? "spin"
                        : isPlaceBet
                        ? betSlipActions.PLACE_BET
                        : nextAction
                    }
                  />
                  {showSectionIfText(contentfulStrings("eighteenPlus")) &&
                    isGermany && (
                      <MarkdownContent
                        className={classNames(styles.dynamicEighteenPlus)}
                      >
                        {contentfulStrings("eighteenPlus")}
                      </MarkdownContent>
                    )}
                </section>
              </div>
            ) : nextAction === betSlipActions.BETTING_NOT_STARTED ? (
              <BettingNotStarted
                betStore={betStore}
                contentfulStrings={contentfulStrings}
              />
            ) : (
              <ErrorComponent
                type={
                  !isEligibleToBet
                    ? betErrorTypes.INELIGIBLE
                    : nextAction === betSlipActions.BET_CLOSED
                    ? betErrorTypes.NO_LONGER_AVAILABLE
                    : betErrorTypes.API_ERROR
                }
                contentfulStrings={contentfulStrings}
                betStore={betStore}
                isGermany={isGermany}
              />
            )}

            {isEventLive &&
              !isBetPlaced &&
              eventStatus !== betStatusConstants.COMPLETED &&
              !isHistoryLoading &&
              showSectionIfText(contentfulStrings("fixtureStartedText")) && (
                <div className={styles.infoText}>
                  {contentfulStrings("fixtureStartedText")}
                </div>
              )}

            {isBetPlaced && isEventLive && (
              <div className={styles.infoText}>
                {contentfulStrings("betPlacedEventLiveText")}
              </div>
            )}

            {!isBetPlaced && isEventCompleted && !isHistoryLoading && (
              <div className={styles.infoText}>
                {contentfulStrings("betNotPlacedEventLive")}
              </div>
            )}

            {isUserWonAtleastOne &&
              eventStatus === betStatusConstants.COMPLETED && (
                <section onClick={handleMarkdownLink}>
                  <MarkdownContent
                    className={classNames(styles.winningText)}
                    component="p"
                  >
                    {contentfulStrings("winningText")}
                  </MarkdownContent>
                </section>
              )}

            {((nextAction !== betSlipActions.REGISTER &&
              nextAction !== betSlipActions.INELIGIBLE &&
              !isApiError &&
              !isEventLive) ||
              isEventCompleted) &&
              showSectionIfText(contentfulStrings("moreBetsButton")) && (
                <div
                  className={classNames(styles.moreBetsDiv)}
                  onClick={handleMoreBets}
                >
                  <div className={styles.moreBetsinnerDiv}>
                    <LinkIcon fill="#007AFF" />
                    <p className={classNames(styles.moreBets)}>
                      {contentfulStrings("moreBetsButton")}
                    </p>
                  </div>
                </div>
              )}

            {isEventLive &&
              showSectionIfText(contentfulStrings("liveTrackigText")) && (
                <div
                  className={classNames(styles.liveTrackingDiv)}
                  onClick={handleLiveTracking}
                >
                  <div className={styles.moreBetsinnerDiv}>
                    {/* <LinkIcon fill="#00000" /> */}
                    <p className={classNames(styles.liveTrackingText)}>
                      {contentfulStrings("liveTrackigText")}
                    </p>
                  </div>
                </div>
              )}

            <section className={classNames(styles.footer)}>
              {showSectionIfText(contentfulStrings("eighteenPlus")) &&
                !isGermany && (
                  <p className={styles.eighteenPlus}>
                    {contentfulStrings("eighteenPlus")}
                  </p>
                )}

              {showSectionIfText(contentfulStrings("termsAndConditions")) && (
                <>
                  <MarkdownContent
                    className={classNames(styles.tac)}
                    component="p"
                  >
                    {contentfulStrings("termsAndConditions")}
                  </MarkdownContent>
                  <img
                    className={classNames(styles.tacLogos)}
                    src={contentfulImages("footerLogos")}
                    alt="footer Images"
                  />
                </>
              )}
            </section>
          </div>
        </div>
      )}

      {contentfulData && isGermany && (
        <SlimBreathers contentfulData={contentfulData} />
      )}
    </div>
  );
};
