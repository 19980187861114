export function breatherProxy(item) {
  const backgroundImage = `https:${item?.fields?.tile?.fields?.backgroundImage?.fields?.file?.url}`;
  const title = item?.fields?.tile?.fields?.headLine;
  const theme = item.fields.tile.fields.colorTheme;
  const logo = item?.fields?.tile?.fields?.logo?.fields?.file?.url;
  const buttonDetails = item.fields?.tile?.fields?.buttonCta?.fields;
  const navigation = item?.fields?.tile?.fields?.Navigation;
  const pictorialDisclaimerLogo =
    item?.fields?.tile?.fields?.pictorialDisclaimer?.fields?.logos[0]?.fields
      ?.file?.url;
  const pictorialDisclaimerBgColor =
    item?.fields?.tile?.fields?.pictorialDisclaimer?.fields?.backgroundcolor;
  const textDisclaimer = item?.fields?.tile?.fields?.textDisclaimer;
  const buttonAnalyticsId = item?.fields?.tile?.fields?.buttonAnalyticsId;
  const campaignAnalyticsId = item?.fields?.tile?.fields?.campaignAnalyticsId;
  const subCampaignAnalyticsId =
    item?.fields?.tile?.fields?.subCampaignAnalyticsId;

  return {
    backgroundImage,
    title,
    theme,
    logo,
    buttonDetails,
    navigation,
    pictorialDisclaimerLogo,
    pictorialDisclaimerBgColor,
    textDisclaimer,
    buttonAnalyticsId,
    campaignAnalyticsId,
    subCampaignAnalyticsId,
  };
}
