/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";

import styles from "./styles/ErrorComponent.module.css";
import { ErrorIcon, TryAgain } from "./reusable/svgUtils";
import {
  betCcbEvents,
  betErrorTypes,
  errorMessages,
} from "../../constants/betslip.constants";
import classNames from "classnames";
import { messageToNative } from "../../services/nativeApp.service";

export function ErrorComponent({
  type,
  contentfulStrings,
  betStore,
  isGermany,
}) {
  const { getBetDetails, setIsApiError, betDetailsRes } = betStore;
  const [isLoading, setIsLoading] = useState(false);
  const isInEligile = type === betErrorTypes.INELIGIBLE;
  const isApiError = type === betErrorTypes.API_ERROR;
  const isNoLongerAvailable = type === betErrorTypes.NO_LONGER_AVAILABLE;
  const isGeoLocationError =
    errorMessages.geoLocationError.toLowerCase() ===
    betDetailsRes?.error?.toLowerCase();

  useEffect(() => {
    if (isInEligile && isGermany) {
      const hideBetslip = () =>
        messageToNative(betCcbEvents.HIDE_BETSLIP_TAB, {});
      setTimeout(hideBetslip, 4000);
    }
  }, [isInEligile, isGermany]);

  const handleTryAgain = async () => {
    setIsLoading(true);
    const res = await getBetDetails();
    if (res?.status === 200) {
      setIsApiError(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
      analyticEvent: betDetailsRes?.nextAction,
    });
  }, []);

  if (isNoLongerAvailable) return null;

  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.main)}>
        <div className={classNames(styles.iconDiv)}>
          <ErrorIcon />
        </div>
        <h2 className={classNames(styles.title)}>
          {" "}
          {isInEligile
            ? contentfulStrings("ineligibleToBetText")
            : isNoLongerAvailable
            ? contentfulStrings("fixtureStartedText")
            : isGeoLocationError
            ? contentfulStrings("geoLocationErrorTitle")
            : contentfulStrings("unexpectedError")}
        </h2>
        {isApiError && !isGeoLocationError && (
          <p className={classNames(styles.description)}>
            {/* {betDetails.message} */}
            {contentfulStrings("unexpectedErrorSubText")}
          </p>
        )}
        {isGeoLocationError && (
          <p className={classNames(styles.description)}>
            {contentfulStrings("geoLocationErrorDescription")}
          </p>
        )}
        {isInEligile && (
          <p className={classNames(styles.description)}>
            {contentfulStrings("betttingUnavailableSubText")}
          </p>
        )}
        {isNoLongerAvailable && (
          <p className={classNames(styles.description)}>
            {contentfulStrings("fixtureStartedSubText")}
          </p>
        )}
      </div>
      {isApiError && !isGeoLocationError && (
        <div
          className={classNames(styles.tryAgainDiv)}
          onClick={handleTryAgain}
        >
          <TryAgain isLoading={isLoading} />
          <p className={classNames(styles.tryAgainText)}>
            {contentfulStrings("tryAgain")}
          </p>
        </div>
      )}
    </div>
  );
}
