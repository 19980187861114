import React, { useEffect } from "react";
import MarkdownContent from "../MarkdownContent/MarkdownContent";
import classNames from "classnames";

import styles from "./OddsChecker.module.css";
import { useOddsCheckerData } from "./useOddsCheckerData";
import { betCcbEvents } from "../../constants/betslip.constants";
import { messageToNative } from "../../services/nativeApp.service";

function OddsChecker() {
  const { data, isLoading } = useOddsCheckerData();

  useEffect(() => {
    // Web Analytics
    const onPageLoadFunction = () =>
      messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
        analyticEvent: "onPageLoad",
      });

    setTimeout(onPageLoadFunction, 4000);
  }, []);

  const isMobile = true;

  if (isLoading) {
    return (
      <div className={styles.skeletonContainer}>
        <div className={styles.skeletonTitle} />
        <div className={styles.skeletonBody} />
      </div>
    );
  }

  return (
    <>
      {data?.map((oddChecker) => (
        <div
          className={styles.container}
          // eslint-disable-next-line react/no-array-index-key
          key={`odds_${oddChecker?.heading}`}
        >
          <MarkdownContent className={styles.oddsTitle}>
            {oddChecker?.title}
          </MarkdownContent>
          <div>
            <MarkdownContent className={styles.mainHeader}>
              {oddChecker.heading}
            </MarkdownContent>
            <div className={styles.mainWrapper}>
              <div className={styles.innerHeader}>
                <MarkdownContent>{oddChecker.columnHeadingOne}</MarkdownContent>
                <MarkdownContent>{oddChecker.columnHeadingTwo}</MarkdownContent>
              </div>
              <div className={styles.rowsContainer}>
                {!!oddChecker?.odds?.length &&
                  oddChecker.odds.map((odd) => (
                    <div
                      className={styles.oddsRow}
                      onClick={() => {
                        messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
                          analyticEvent: "oddsCheckerClick",
                        });
                        messageToNative(betCcbEvents.DAZN_BET, {
                          isExternal: true,
                          url: odd?.button?.link,
                        });
                      }}
                    >
                      <div className={styles.rowCell}>
                        <img
                          src={`https:${
                            isMobile
                              ? odd?.logoImage?.mobile
                              : odd?.logoImage?.default
                          }`}
                          alt={"oddsChecker logo"}
                        />
                      </div>
                      <div
                        className={classNames(styles.rowCell, styles.oddsValue)}
                        style={{
                          color: odd?.oddsValueColor ?? "#FFFFFF",
                        }}
                      >
                        {odd.oddsValue}
                      </div>
                      <div
                        className={classNames(
                          styles.rowCell,
                          styles.descriptionContainer
                        )}
                      >
                        <MarkdownContent className={styles.description}>
                          {odd.description}
                        </MarkdownContent>

                        {isMobile && (
                          <div className={styles.chevronBox}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 7 11"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.58586 5.50001L0.292969 2.20712L1.70718 0.792908L6.41429 5.50001L1.70718 10.2071L0.292969 8.79291L3.58586 5.50001Z"
                                fill="#F9FAFA"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                      {!isMobile && (
                        <div
                          className={classNames(
                            styles.rowCell,
                            styles.buttonContainer
                          )}
                          style={{
                            backgroundColor: odd?.button?.color
                              ? odd?.button?.color
                              : "#f7ff1a",
                          }}
                        >
                          {odd?.button?.betButtonLabel}
                          <img
                            src={`https:${odd?.button?.image?.src}`}
                            alt="chevron"
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {oddChecker?.legalDescription && (
            <MarkdownContent className={styles.terms}>
              {oddChecker?.legalDescription}
            </MarkdownContent>
          )}

          <div className={styles.tradeMark}>
            {oddChecker.trademarkImage && (
              <div>
                <img
                  src={
                    isMobile
                      ? oddChecker?.trademarkImage?.mobile
                      : oddChecker?.trademarkImage?.src
                  }
                  alt="oddsChecker trademark"
                  className={styles.tradeMarkImage}
                />
              </div>
            )}
            {oddChecker.poweredByImage && (
              <div>
                <img
                  src={
                    isMobile
                      ? oddChecker?.poweredByImage?.mobile
                      : oddChecker?.poweredByImage?.src
                  }
                  alt="OddsCheckher Powdered by"
                  className={styles.tradeMarkImage}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export { OddsChecker };
