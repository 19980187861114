import { betSlipActions } from "../constants/betslip.constants";

export const nativeUiConfig = {
  GB: {
    showNavBar: true,
    screenPercentage: {
      [betSlipActions.LOGIN]: 66.6,
      [betSlipActions.REGISTER]: 90,
    },
    dismissible: false,
    isFullScreen: false,
  },
  ES: {
    showNavBar: true,
    screenPercentage: {
      [betSlipActions.LOGIN]: 66.6,
      [betSlipActions.REGISTER]: 90,
    },
    dismissible: false,
    isFullScreen: false,
  },
  IT: {
    showNavBar: true,
    screenPercentage: {
      [betSlipActions.LOGIN]: 66.6,
      [betSlipActions.REGISTER]: 90,
    },
    dismissible: false,
    isFullScreen: false,
  },
  DE: {
    showNavBar: true,
    screenPercentage: {
      [betSlipActions.LOGIN]: 66.6,
      [betSlipActions.REGISTER]: 90,
    },
    dismissible: true,
    isFullScreen: false,
  },
  DEFAULT: {
    showNavBar: true,
    screenPercentage: {
      [betSlipActions.LOGIN]: 66.6,
      [betSlipActions.REGISTER]: 90,
    },
    dismissible: false,
    isFullScreen: false,
  },
};
